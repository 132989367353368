<template>
  <v-switch
    v-model="selectedVal"
    class="ma-0 pa-0"
    color="black"
    hide-details
    inset>
    <template #label>
      <span class="fs-12 font-weight-bold text-uppercase black--text">
        Verified Only
      </span>
    </template>
  </v-switch>
</template>

<script>
import {
  mapGetters, mapMutations,
} from 'vuex';

export default {
  name: 'SearchFilterVerifiedOnly',
  computed: {
    ...mapGetters(['getVerifiedSearch']),
    selectedVal: {
      get() {
        return this.getVerifiedSearch;
      },
      set(val) {
        this.setVerifiedSearch(val);
      },
    },
  },
  methods: {
    ...mapMutations(['setVerifiedSearch']),
  },
};
</script>